<template>
  <b-modal :active="true" has-modal-card trap-focus aria-role="dialog" aria-modal>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">When is my first issue coming?</p>
      </header>
      <section class="modal-card-body">
        <p>
          <b>1.</b>
          Add the below customer service to the ‘to’ of your reply to the customer in Freskdesk, ensure the customer is still included.
        </p>
        <contact-table :contact="this.fulfilment.CustomerServiceContacts[0]" :show-copy="true"></contact-table>
        <p>
          <b>2.</b>&nbsp;
          <span>Copy the below text in to your email reply to the customer in Freshdesk. Along with anything else necessary to personalise/ make relevant to the customer's email then send.</span>
        </p>

        <div class="freshdeskcopy">
          <div class="copy">
            <span class="title is-6">Email Template to copy</span>
            <button
              class="button is-small"
              v-on:click="copyToClipboard('freshDeskCopyAddressChanges')"
            >Copy</button>
          </div>
          <div class="copybody">
            <div id="freshDeskCopyAddressChanges">
              <p>Hi {{customerReplyName}}</p>
              <p>Thank you for your email.</p>

              <p v-if="fulfilment.BatchJobId !== null">I have checked our system and can see that your order was successfully sent to the publisher on the {{ fulfilment.BatchJobInfo.RunAt }}</p>

              <p>As the magazines are mailed by the publisher I have copied them into this thread so that they can respond to you directly. Please 'reply all' to this thread should you have further questions relating to this order.</p>
              
              <p v-if="fulfilment.BatchJobId !== null">
                {{ fulfilment.CustomerServiceContacts[0].Email }}, please could you advise when the first issue of the below subscription will be delivered to this customer? Please can you confirm to us both by ‘replying all’ to this email?
              </p>
              <div v-else>
                <p>{{ fulfilment.CustomerServiceContacts[0].Email }}, the below order will be sent across to you shortly in the next batch of processed orders.</p>
                <p>Please could you let us know when you anticipate the first issue of the subscription will be delivered to the customer? Please could you confirm to us both by replying all to this email?</p>
                <p></p>
              </div>


              <p>For the publisher's reference, we can confirm the following order details:</p>

              <fulfilment-info-for-bureau
                :fulfilment="fulfilment"
                class="table is-size-8"
                style="width:auto;"
              ></fulfilment-info-for-bureau>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="Close">Close</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import { baseMixin } from "../../../mixins/baseMixin.js";
//fulfilment-info-for-bureau
export default {
  name: "fulfilmentFirstIssueEmailTemplateDialog",
  mixins: [baseMixin],
  //canCancel
  components: {
    fulfilmentInfoForBureau: () =>
      import("../../components/molecules/fulfilmentInfoForBureau.vue"),
    contactTable: () => import("../../components/molecules/contactTable.vue")
  },
  props: {
    fulfilment: Object
  },
  data() {
    return {
      APIUrl: process.env.VUE_APP_APIURL
    };
  },
  methods: {
    Close: function() {
      //emit close!
      this.$emit("close");
    }
  },
  computed: {
    customerReplyName() {
      return this.fulfilment.Data.primary_contact.primary_contact_first_name;
    }
  }
};
</script>
